<template>
  <GlPageWrap title="My Account">
    <div class="content-wrap">
      <div class="bold-600 mb-4">
        Account info
      </div>
      <div class="flex space-between align-end mb-5">
        <InfoBlock
          label="Login"
          :value="userData.email"
        />
        <InfoBlock
          label="Number of requests left"
          :value="userData.requestsLeft"
        />
        <div class="flex align-end">
          <InfoBlock
            class="mr-2"
            label="Active to"
            :value="userData.activeTo ? formatDate(userData.activeTo, 'dd.MM.yyyy') : null"
          />
          <gl-icon
            v-if="alarmActiveTo(userData.activeTo)"
            v-tooltip="'Your account will expire soon, please contact support to renew it'"
            class="mr-2 gl-icon--disabled"
            :height="24"
            name="def-report"
            :width="24"
          />
        </div>
        <button
          class="gl-button gl-button--dark gl-button--padder"
          @click="resetPasswordModal = true"
        >
          Change password
        </button>
      </div>
      <div class="bold-600 mb-4">
        Scoring Settings
      </div>
      <div class="mb-5">
        <div class="tagging__key">
          Scoring formula
        </div>
        <div class="flex">
          <vSelect
            v-model="scoringFormula"
            class="scoring-formula-select mr-4"
            :clearable="false"
            :options="scoringFormulaList"
            @input="formulaChange"
          />
          <button
            class="gl-button gl-button--dark gl-button--padder"
            @click="restoreScoringSettings"
          >
            Restore Defaults
          </button>
        </div>
      </div>
      <div class="bold-600 mb-4">
        API Keys
      </div>
      <div>
        <InfoBlock
          class="mb-4"
          label="API KEY"
          :value="userData.apikey"
          with-copy
        />
        <InfoBlock
          label="API Secret"
          :value="userData.apisecret"
          with-copy
        />
      </div>
    </div>
    <reset-password-modal
      v-if="resetPasswordModal"
      v-model="resetPasswordModal"
      :email="userData.email"
      @close="resetPasswordModal = false"
      @submit="resetPasswordModal = false"
    />
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapState, mapActions } from "vuex";
// Components
import vSelect from 'vue-select'
import GlIcon from "@/components/gl-icon";
import InfoBlock from "@/components/gl-info-block";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import ResetPasswordModal from '../login/modals/resetPasswordModal'
// Utils
import { formatDate } from '@/utils/format-date'
import { alarmActiveTo } from "@/utils/profile";
import config from "@/utils/appConfig";

export default {
  components: {
    GlIcon,
    vSelect,
    InfoBlock,
    GlPageWrap,
    ResetPasswordModal
  },
  data() {
    return {
      resetPasswordModal: false,
      scoringFormulaList: ['Default', 'Sensitive'],
      scoringFormula: 'Default'
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    isActiveToMode() {
      return config.VUE_APP_ACTIVE_TO
    },
  },
  mounted() {
    this.scoringFormula = this.userData.scoringFormula
  },
  methods: {
    ...mapActions('user', ['setScoringFormula', 'getMe']),
    formatDate,
    alarmActiveTo,
    formulaChange(scoringFormula) {
      this.setScoringFormula({ scoringFormula }).then(({ success }) => {
        if (success) {
          this.scoringFormula = scoringFormula
          this.getMe()
        }
      })
    },
    restoreScoringSettings() {
      this.formulaChange('Default')
    },
  },
}
</script>

<style>
.profile-page-wrapper {
  padding: 30px 40px;
  overflow: scroll;
}
.profile-page-title {
  /*text-transform: uppercase;*/
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 24px;
  color: var(--space-cadet);
}

.content-wrap {
  background: var(--white);
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
  padding: 24px;
}

.scoring-formula-select {
  width: 240px;
}
</style>

